import i18next from 'i18next';
import {all, takeEvery, fork, call, put} from 'redux-saga/effects';
import ClientsService from '@iso/lib/services/ClientsService';
import actions, { ascendingSort } from './actions';
import createNotification from '../../components/Notification';

export function* getAllClients() {
  yield takeEvery(actions.GET_ALL_CLIENTS, function*() {
    const result = yield call(ClientsService.all);
    if (!result.error && result.data) {
      yield put({
        type: actions.GET_ALL_CLIENTS_SUCCESS,
        clients: result.data.sort(ascendingSort),
      });
    } else {
      createNotification('error', result.error);
      yield put({ type: actions.GET_ALL_CLIENTS_ERROR });
    }
  });
}

export function* createClient() {
  yield takeEvery(actions.CREATE_CLIENT, function*({ clients, client }) {
    const result = yield call(ClientsService.create, client);
    if (!result.error) {
      client.id = result.data.id;
      clients.push(client);
      yield put({
        type: actions.CREATE_CLIENT_SUCCESS,
        selectedId: result.data.id,
        clients,
      });
    } else {
      createNotification('error', result.message[0]);
      yield put({ type: actions.CREATE_CLIENT_ERROR });
    }
  });
}

export function* updateClient() {
  yield takeEvery(actions.UPDATE_CLIENT, function*({ clients, client }) {
    const result = yield call(ClientsService.update, client);
    if (!result.error) {
      yield put({
        type: actions.UPDATE_CLIENT_SUCCESS,
        selectedId: client.id,
        clients,
      });
    } else {
      createNotification('error', result.message[0]);
      yield put({ type: actions.UPDATE_CLIENT_ERROR });
    }
  });
}

export function* deleteClient() {
  yield takeEvery(actions.DELETE_CLIENT, function*({ clients, id }) {
    const result = yield call(ClientsService.delete, id);
    if (!result.error) {
      createNotification('error', i18next.t('client.deleted', { id }));
      yield put({
        type: actions.DELETE_CLIENT_SUCCESS,
        clients: clients.filter(client => client.id !== id),
      });
    } else {
      createNotification('error', result.message[0]);
      yield put({ type: actions.DELETE_CLIENT_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllClients),
    fork(createClient),
    fork(updateClient),
    fork(deleteClient)
  ]);
}

import SuperFetch from '../helpers/superFetch';

class ClientsService {
  all = async () => {
    return await SuperFetch.get('v1/clients');
  };
  get = async (id) => {
    return await SuperFetch.get(`v1/clients/${id}`);
  };
  create = async (client) => {
    return await SuperFetch.post('v1/clients', client);
  };
  update = async (client) => {
    return await SuperFetch.put(`v1/clients/${client.id}`, client);
  };
  delete = async (id) => {
    return await SuperFetch.delete(`v1/clients/${id}`);
  };
}
export default new ClientsService();

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import Enlang from './entries/en-US';
import Zhlang from './entries/zh-Hans-CN';
import Salang from './entries/ar_SA';
import Itlang from './entries/it_IT';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';

const AppLocale = {
  en: Enlang,
  zh: Zhlang,
  sa: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
};

export { AppLocale };

const resources = {
  en: { translation: Enlang.messages },
};

const I18NOptions = {
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
};

i18next.use(initReactI18next).init(I18NOptions);

export default i18next;


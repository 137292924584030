import jwtConfig from '@iso/config/jwt.config';

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const base = (method, url, data = null) => {
  const init = {
    method,
    headers: customHeader(),
  };

  if (data) {
    init.body = JSON.stringify(data);
  }

  return fetch(`${jwtConfig.fetchUrl}${url}`, init)
    .then(async response => {
      try {
        return await response.json();
      } catch (e) {
        return response;
      }
    })
    .then(res => res)
    .catch(error => {
      return { error: 'server.error' };
    });
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;

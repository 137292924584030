import i18next from 'i18next';
import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import ContractsService from '@iso/lib/services/ContractsService';
import ClientsService from '@iso/lib/services/ClientsService';
import actions from './actions';
import createNotification from '../../components/Notification';

export function* getAllContracts() {
  yield takeEvery(actions.GET_ALL_CONTRACTS, function*() {
    const result = yield call(ContractsService.all);
    if (!result.error && result.data) {
      yield put({
        type: actions.GET_ALL_CONTRACTS_SUCCESS,
        contracts: result.data,
      });
    } else {
      createNotification('error', result.error);
      yield put({ type: actions.GET_ALL_CONTRACTS_ERROR });
    }
  });
}

export function* fetchContract() {
  yield takeEvery(actions.FETCH_CONTRACT, function*({ contracts, id }) {
    const result = yield call(ContractsService.get, id);
    if (!result.error && result.data) {
      const index = contracts.findIndex(contract => contract.id === id);
      contracts[index] = result.data;
      yield put({
        type: actions.FETCH_CONTRACT_SUCCESS,
        contracts: [...contracts],
      });
    } else {
      createNotification('error', 'Não foi possivel imprimir o contrato');
      yield put({ type: actions.FETCH_CONTRACT_ERROR });
    }
  });
}

export function* createContract() {
  yield takeEvery(actions.CREATE_CONTRACT, function*({ contracts, contract }) {
    const result = yield call(ContractsService.create, contract);
    if (!result.error) {
      contract.id = result.data.id;
      contract.contract_number = result.data.contract_number;
      contract.contract_seller_name = result.data.contract_seller_name;
      contract.contract_buyer_name = result.data.contract_buyer_name;
      contracts.unshift(contract);
      yield put({
        type: actions.CREATE_CONTRACT_SUCCESS,
        selectedId: result.data.id,
        contracts,
      });
    } else {
      createNotification('error', result.message);
      yield put({ type: actions.CREATE_CONTRACT_ERROR });
    }
  });
}

export function* updateContract() {
  yield takeEvery(actions.UPDATE_CONTRACT, function*({ contracts, contract }) {
    const result = yield call(ContractsService.update, contract);
    if (!result.error) {
      yield put({
        type: actions.UPDATE_CONTRACT_SUCCESS,
        selectedId: contract.id,
        contracts,
      });
    } else {
      createNotification('error', result.message);
      yield put({ type: actions.UPDATE_CONTRACT_ERROR });
    }
  });
}

export function* deleteContract() {
  yield takeEvery(actions.DELETE_CONTRACT, function*({ contracts, id }) {
    const result = yield call(ContractsService.delete, id);
    if (!result.error) {
      createNotification('error', i18next.t('contract.deleted', { id }));
      yield put({
        type: actions.DELETE_CONTRACT_SUCCESS,
        contracts: contracts.filter(contract => contract.id !== id),
      });
    } else {
      createNotification('error', result.message);
      yield put({ type: actions.DELETE_CONTRACT_ERROR });
    }
  });
}

export function* getClient() {
  yield takeEvery(actions.GET_CLIENT, function*({ contract, clientId, clientType }) {
    const result = yield call(ClientsService.get, clientId);
    if (!result.error && result.data) {
      if (clientType === 'seller') {
        contract.seller = result.data;
        contract.contract_payment_details = null;
        contract.seller_bank_list = null;
        if (contract.seller.client_person_type === 1) {
          contract.contract_seller_name = contract.seller.client_individual_name;
        } else {
          contract.contract_seller_name = contract.seller.client_business_name;
        }
      } else {
        contract.buyer = result.data;
        if (contract.buyer.client_person_type === 1) {
          contract.contract_buyer_name = contract.buyer.client_individual_name;
        } else {
          contract.contract_buyer_name = contract.buyer.client_business_name;
        }
      }
      yield put({
        type: actions.GET_CLIENT_SUCCESS,
        contract,
      });
    } else {
      if (clientType === 'seller') {
        createNotification('error', 'Vendedor nao existe');
        contract.seller = null;
        contract.contract_seller_id = null;
        contract.contract_seller_name = null;
        contract.contract_payment_details = null;
        contract.seller_bank_list = null;
      } else {
        createNotification('error', 'Comprador nao existe');
        contract.buyer = null;
        contract.contract_buyer_id = null;
        contract.contract_buyer_name = null;
      }
      yield put({
        type: actions.GET_CLIENT_ERROR,
        contract,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllContracts),
    fork(createContract),
    fork(updateContract),
    fork(deleteContract),
    fork(fetchContract),
    fork(getClient),
  ]);
}

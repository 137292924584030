import SuperFetch from '../helpers/superFetch';

class ContractsService {
  all = async () => {
    return await SuperFetch.get('v1/contracts');
  };
  get = async (id) => {
    return await SuperFetch.get(`v1/contracts/${id}`);
  };
  create = async (contract) => {
    return await SuperFetch.post('v1/contracts', contract);
  };
  update = async (contract) => {
    return await SuperFetch.put(`v1/contracts/${contract.id}`, contract);
  };
  delete = async (id) => {
    return await SuperFetch.delete(`v1/contracts/${id}`);
  };
}
export default new ContractsService();

import moment from 'moment';

const contractActions = {
  GET_ALL_CONTRACTS: 'GET_ALL_CONTRACTS',
  GET_ALL_CONTRACTS_SUCCESS: 'GET_ALL_CONTRACTS_SUCCESS',
  GET_ALL_CONTRACTS_ERROR: 'GET_ALL_CONTRACTS_ERROR',

  getAllContracts: () => ({
    type: contractActions.GET_ALL_CONTRACTS
  }),

  CREATE_CONTRACT: 'CREATE_CONTRACT',
  CREATE_CONTRACT_SUCCESS: 'CREATE_CONTRACT_SUCCESS',
  CREATE_CONTRACT_ERROR: 'CREATE_CONTRACT_ERROR',

  createContract: (newContract) => {
    return (dispatch, getState) => {
      dispatch({
        type: contractActions.CREATE_CONTRACT,
        contracts: getState().Contracts.contracts,
        contract: newContract,
      });
    };
  },

  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  UPDATE_CONTRACT_SUCCESS: 'UPDATE_CONTRACT_SUCCESS',
  UPDATE_CONTRACT_ERROR: 'UPDATE_CONTRACT_ERROR',

  updateContract: (contract) => {
    return (dispatch, getState) => {
      dispatch({
        type: contractActions.UPDATE_CONTRACT,
        contracts: getState().Contracts.contracts,
        contract,
      });
    };
  },

  DELETE_CONTRACT: 'DELETE_CONTRACT',
  DELETE_CONTRACT_SUCCESS: 'DELETE_CONTRACT_SUCCESS',
  DELETE_CONTRACT_ERROR: 'DELETE_CONTRACT_ERROR',

  deleteContract: (id) => {
    return (dispatch, getState) => {
      dispatch({
        type: contractActions.DELETE_CONTRACT,
        contracts: getState().Contracts.contracts,
        id,
      });
    };
  },

  GET_CLIENT: 'GET_CLIENT',
  GET_CLIENT_SUCCESS: 'GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR: 'GET_CLIENT_ERROR',

  getClient: (clientId, clientType) => {
    return (dispatch, getState) => {
      dispatch({
        type: contractActions.GET_CLIENT,
        contract: getState().Contracts.contract ? getState().Contracts.contract : getState().Contracts.contracts.filter((contract) => contract.id === getState().Contracts.selectedId)[0],
        clientId,
        clientType,
      });
    };
  },

  FETCH_CONTRACT: 'FETCH_CONTRACT',
  FETCH_CONTRACT_SUCCESS: 'FETCH_CONTRACT_SUCCESS',
  FETCH_CONTRACT_ERROR: 'FETCH_CONTRACT_ERRORs',

  fetchContract: (id) => {
    return (dispatch, getState) => {
      dispatch({
        type: contractActions.FETCH_CONTRACT,
        contracts: getState().Contracts.contracts,
        id,
      });
    };
  },

  ADD_CONTRACT: 'ADD_CONTRACT',
  EDIT_CONTRACT: 'EDIT_CONTRACT',
  CHANGE_CONTRACT: 'CHANGE_CONTRACT',
  EDIT_VIEW: 'EDIT_VIEW',

  changeContract: id => ({
    type: contractActions.CHANGE_CONTRACT,
    id,
  }),

  addContract: () => ({
    type: contractActions.ADD_CONTRACT,
    contract: {
      id: null,
      contract_number: '',
      contract_date: moment().format('DD/MM/YYYY'),
      contract_payment_date: null,
    },
  }),

  editContract: newContract => ({
    type: contractActions.EDIT_CONTRACT,
    contract: newContract,
  }),

  viewChange: view => ({
    type: contractActions.EDIT_VIEW,
    view,
  }),
};
export default contractActions;

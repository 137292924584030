import actions from './actions';

const initState = {
  idToken: null,
  loading: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...initState,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...initState,
        idToken: action.token,
      };
    case actions.LOGIN_ERROR:
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}

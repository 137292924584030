import contractActions from './actions';

const contracts = [];

const initState = {
  contracts,
  selectedId: null,
  editView: false,
  contract: null,
  isLoading: false,
  isFetchingContract: false,
};

export default function contractReducer(state = initState, action) {
  switch (action.type) {
    case contractActions.GET_ALL_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.contracts,
        editView: false,
      };
    case contractActions.CREATE_CONTRACT:
      return {
        ...state,
        selectedId: null,
        editView: true,
        isLoading: true,
        contract: action.contract,
      };
    case contractActions.CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        contracts: action.contracts,
        selectedId: action.selectedId,
        editView: false,
        isLoading: false,
        contract: null,
      };
    case contractActions.CREATE_CONTRACT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case contractActions.UPDATE_CONTRACT:
      return {
        ...state,
        editView: true,
        isLoading: true,
        contract: action.contract,
      };
    case contractActions.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        contracts: action.contracts,
        selectedId: action.selectedId,
        editView: false,
        isLoading: false,
        contract: null,
      };
    case contractActions.UPDATE_CONTRACT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case contractActions.DELETE_CONTRACT:
      return {
        ...state,
        isLoading: true,
        id: action.id,
      };
    case contractActions.DELETE_CONTRACT_SUCCESS:
      return {
        ...initState,
        contracts: action.contracts,
      };
    case contractActions.DELETE_CONTRACT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case contractActions.GET_CLIENT:
      return {
        ...state,
        isLoading: true,
        contract: action.contract,
        clientId: action.clientId,
        clientType: action.clientType,
      };
    case contractActions.GET_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case contractActions.GET_CLIENT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case contractActions.FETCH_CONTRACT:
      return {
        ...state,
        isFetchingContract: true,
      };
    case contractActions.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        isFetchingContract: false,
        contracts: action.contracts,
      };
    case contractActions.FETCH_CONTRACT_ERROR:
      return {
        ...state,
        isFetchingContract: false,
      };
    case contractActions.CHANGE_CONTRACT:
      return {
        ...state,
        selectedId: action.id,
        editView: true,
        contract: null,
      };
    case contractActions.ADD_CONTRACT:
      return {
        ...state,
        selectedId: null,
        editView: true,
        contract: action.contract,
      };
    case contractActions.EDIT_CONTRACT:
      return {
        ...state,
        contract: action.contract,
      };
    case contractActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
        contract: null,
      };
    default:
      return state;
  }
}

import clientActions from './actions';

const clients = [];

const initState = {
  clients,
  selectedId: null,
  editView: false,
  client: null,
  isLoading: false,
};

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case clientActions.GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        editView: false,
      };
    case clientActions.CREATE_CLIENT:
      return {
        ...state,
        selectedId: null,
        editView: true,
        isLoading: true,
        client: action.client,
      };
    case clientActions.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        selectedId: action.selectedId,
        editView: false,
        isLoading: false,
        client: null,
      };
    case clientActions.CREATE_CLIENT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case clientActions.UPDATE_CLIENT:
      return {
        ...state,
        editView: true,
        isLoading: true,
        client: action.client,
      };
    case clientActions.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        selectedId: action.selectedId,
        editView: false,
        isLoading: false,
        client: null,
      };
    case clientActions.UPDATE_CLIENT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case clientActions.DELETE_CLIENT:
      return {
        ...state,
        isLoading: true,
        id: action.id,
      };
    case clientActions.DELETE_CLIENT_SUCCESS:
      return {
        ...initState,
        clients: action.clients,
      };
    case clientActions.DELETE_CLIENT_ERROR:
      return {
        ...state,
        editView: true,
        isLoading: false,
      };
    case clientActions.CHANGE_CLIENT:
      return {
        ...state,
        selectedId: action.id,
        editView: false,
        client: null,
      };
    case clientActions.ADD_CLIENT:
      return {
        ...state,
        selectedId: null,
        editView: true,
        client: action.client,
      };
    case clientActions.EDIT_CLIENT:
      return {
        ...state,
        client: action.client,
      };
    case clientActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view,
        client: null,
      };
    default:
      return state;
  }
}

export { clients };

export const ascendingSort = (client1, client2) => {
  let name1;
  let name2;
  if (client1.client_person_type === 1) {
    name1 = client1.client_individual_name ? client1.client_individual_name : client1.client_contact;
  } else {
    name1 = client1.client_business_name ? client1.client_business_name : client1.client_contact;
  }
  if (client2.client_person_type === 1) {
    name2 = client2.client_individual_name ? client2.client_individual_name : client2.client_contact;
  } else {
    name2 = client2.client_business_name ? client2.client_business_name : client2.client_contact;
  }
  name1 = name1 !== '' ? name1.toUpperCase() : '~';
  name2 = name2 !== '' ? name2.toUpperCase() : '~';
  if(name1 > name2) return 1;
  if(name1 < name2) return -1;
  return 0;
};

const clientActions = {
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  GET_ALL_CLIENTS_SUCCESS: 'GET_ALL_CLIENTS_SUCCESS',
  GET_ALL_CLIENTS_ERROR: 'GET_ALL_CLIENTS_ERROR',

  getAllClients: () => ({
    type: clientActions.GET_ALL_CLIENTS
  }),

  CREATE_CLIENT: 'CREATE_CLIENT',
  CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_ERROR: 'CREATE_CLIENT_ERROR',

  createClient: (newClient) => {
    return (dispatch, getState) => {
      dispatch({
        type: clientActions.CREATE_CLIENT,
        clients: getState().Clients.clients,
        client: newClient,
      });
    };
  },

  UPDATE_CLIENT: 'UPDATE_CLIENT',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_ERROR: 'UPDATE_CLIENT_ERROR',

  updateClient: (client) => {
    return (dispatch, getState) => {
      dispatch({
        type: clientActions.UPDATE_CLIENT,
        clients: getState().Clients.clients,
        client,
      });
    };
  },

  DELETE_CLIENT: 'DELETE_CLIENT',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR: 'DELETE_CLIENT_ERROR',

  deleteClient: (id) => {
    return (dispatch, getState) => {
      dispatch({
        type: clientActions.DELETE_CLIENT,
        clients: getState().Clients.clients,
        id,
      });
    };
  },

  ADD_CLIENT: 'ADD_CLIENT',
  EDIT_CLIENT: 'EDIT_CLIENT',
  CHANGE_CLIENT: 'CHANGE_CLIENT',
  EDIT_VIEW: 'EDIT_VIEW',

  changeClient: id => ({
    type: clientActions.CHANGE_CLIENT,
    id,
  }),

  addClient: () => ({
    type: clientActions.ADD_CLIENT,
    client: {
      id: null,
      client_contact: '',
      client_tax_id: '',
      client_details: '',
      client_short_name: '',
      client_email: '',
      client_person_type: 1,
      client_individual_name: '',
      client_individual_tax_id: '',
      client_business_name: '',
      client_business_tax_id: '',
      client_phone: '',
      client_mobile: '',
      client_address: '',
      client_address_city: '',
      client_address_city_district: '',
      client_address_state: '',
      client_address_zipcode: '',
      client_address_notes: '',
      client_bank_list: [],
    },
  }),

  editClient: newClient => ({
    type: clientActions.EDIT_CLIENT,
    client: newClient,
  }),

  viewChange: view => ({
    type: clientActions.EDIT_VIEW,
    view,
  }),
};
export default clientActions;
